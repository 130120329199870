import mitt from "mitt"
import type { Agreement } from "core/types/api"

type Events = {
  agreementSigned: Agreement
}

const emitter = mitt<Events>()

export function useEmitter() {
  const emitterProxy = new Proxy(emitter, {
    get(target, prop, receiver) {
      const item = Reflect.get(target, prop, receiver)

      if (prop !== "on") {
        return item
      }

      const onFnProxy = new Proxy(item, {
        apply(target, thisArg, argumentsList: Parameters<typeof emitter.on>) {
          onUnmounted(() => {
            emitter.off(...argumentsList)
          })

          return Reflect.apply(target, thisArg, argumentsList)
        },
      })

      return onFnProxy
    },
  })

  return emitterProxy
}
